<template>
  <div class="engagement-rate">
    <div class="text-h6 font-weight-bold mb-4">
      Engagement Rate
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Average likes divided by Followers.
        </span>
      </v-tooltip>
    </div>

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <stat-box
          :title="rateValue + '%'"
          class="overview-card"
          text="Engagement Rate"
          icon="leaderboard"
        />
      </v-col>

      <v-col cols="12">
        <v-card class="border-radius-1em box-shadow-soft">
          <v-card-text>
            <!-- render the chart component -->
            <apex-chart
              type="bar"
              :height="364"
              :options="chartOptions"
              :series="series"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text small color="primary" @click="shouldShowDialog = true">
              View More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="shouldShowDialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          Engagement
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            disable-pagination
          ></v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import the chid component
const StatBox = () => import(/* webpackChunkName: "blocks-stat-box" */ "@/blocks/user-profile/common/StatBox.vue")

// Import base chart configuration options
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "EngagementRate",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    },

    engagementData: {
      type: Object,
      required: true
    }
  },

  // Register the components
  components: {
    ApexChart,
    StatBox
  },

  // Define local data variables
  data: () => ({
    shouldShowDialog: false,

    tableHeaders: [
      {
        text: "Engagement Rate",
        value: "x",
        sortable: false
      },
      {
        text: "Influencers",
        value: "y",
        sortable: false
      },
    ]
  }),

  // Define readonly computable properties
  computed: {
    /**
     * Get the table row items
     *
     * @returns {Array}
     */
    tableItems() {
      return this.engagementData?.rate ?? []
    },

    /**
     * Get the profile's full name property
     *
     * @returns {String}
     */
    displayName() {
      return this.userData.data.user_profile.fullname || ""
    },

    /**
     * Get the engagement rate percentage value
     *
     * @returns {String}
     */
    rateValue() {
      return (this.userData.data.user_profile.engagement_rate * 100).toFixed(2)
    },

    /**
     * Get the series data for the chart
     *
     * @returns {Array}
     */
    series() {
      return [
        {
          name: "Engagement rate",
          data: this.tableItems,
        },
      ]
    },

    /**
     * Get the chart configuration options
     *
     * @returns {Object}
     */
    chartOptions() {
      const displayName = this.displayName

      return merge(apexChartBaseOptions(), {
        tooltip: {
          x: {
            show: true,
            formatter: (seriesName, opts) =>
              opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
                .description,
            title: {
              formatter: () => "",
            },
          },
          marker: { show: false },
          y: {
            formatter: (seriesName) => `${numeral.fShortenNumber(seriesName)} accounts`,
            title: {
              formatter: () => "",
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          offsetY: -12,
          formatter(value, { seriesIndex, w, dataPointIndex }) {
            if (
              w?.config?.series[seriesIndex]?.data[dataPointIndex]?.user &&
              w?.config?.series[seriesIndex]?.data[dataPointIndex]?.median
            ) {
              return displayName
            }

            if (w?.config?.series[seriesIndex]?.data[dataPointIndex]?.user) {
              return displayName
            }

            if (w?.config?.series[seriesIndex]?.data[dataPointIndex]?.median) {
              return ""
            }
            return ""
          },
          style: {
            colors: [host.theme.color.text],
          },
        },
        colors: [
          ({ value, seriesIndex, w, dataPointIndex }) => {
            if (w?.config?.series[seriesIndex]?.data[dataPointIndex]?.user) {
              // return "#f05d56";
              return "#00bcd4";
            }

            if (w?.config?.series[seriesIndex]?.data[dataPointIndex]?.median) {
              return "#ffd54f";
            }
            return "#ffecb3";
          },
        ],
        stroke: {
          width: 14,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            // vertical: false,
            barHeight: "60%",
            borderRadius: 2,
          },
        },
        yaxis: {
          type: "numeric",
          title: {
            text: "Engagement rate",
            style: {
              // color: "#111",
            },
          },
          labels: {
            formatter: (seriesName) => seriesName,
            style: {
              // colors: "#111",
            },
          },
        },
        xaxis: {
          type: "numeric",
          labels: {
            formatter: (seriesName) => numeral.fShortenNumber(seriesName),
            style: {
              // colors: "#111",
            },
          },
          title: {
            text: "No of accounts",
            style: {
              // color: "#111",
            },
          },
        },
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.overview-card
  color rgb(130, 119, 23) !important
</style>
